// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import Chartkick from "chartkick"
// import Chart from "chart.js"
import "chartkick/highcharts"

import "jquery"

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'popper.js'
import "bootstrap/dist/js/bootstrap.bundle"
import "jquery.easing/jquery.easing"
import '@fortawesome/fontawesome-free/js/all'

import datatable from 'imports-loader?define=>false!datatables.net'
import datatableBS4 from 'imports-loader?define=>false!datatables.net-bs4'

datatable(window, $)
datatableBS4(window, $)

import './shared/sb-admin-2'

import 'select2'

import "./shared/jquery-ui"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
// Chartkick.use(Chart)

$(function() {
  $('.select2-style').select2({
    theme: "bootstrap",
    width: 'style'
  });
  
  window.loading = function() {
    $('#loader-modal').modal('show');
  }

  window.filter = function(e) {
    loading();
    e.closest(".filtering").submit();
    // document.getElementById('filtering').submit();
  }

  $("[data-toggle = 'tooltip']").tooltip();

  // Pages
  if(document.querySelectorAll("#home_controller").length > 0) {
    import("./home") // webpack will load this JS async
  }

  if(document.querySelectorAll("#users_controller").length > 0) {
    import("./user") // webpack will load this JS async
  }

  if(document.querySelectorAll("#businesses_controller").length > 0) {
    import("./business") // webpack will load this JS async
  }

  if(document.querySelectorAll("#groups_controller").length > 0) {
    import("./group") // webpack will load this JS async
  }

  if(document.querySelectorAll("#members_controller").length > 0) {
    import("./member") // webpack will load this JS async
  }

  if(document.querySelectorAll("#cattles_controller").length > 0) {
    import("./cattle") // webpack will load this JS async
  }

  if(document.querySelectorAll("#recordings_controller").length > 0) {
    import("./recording") // webpack will load this JS async
  }

  if(document.querySelectorAll("#reports_controller").length > 0) {
    import("./report") // webpack will load this JS async
  }

  if(document.querySelectorAll("#settings_controller").length > 0) {
    import("./setting") // webpack will load this JS async
  }
});

// Examples
import './shared/demo/datatables-demo'
import './shared/demo/chart-area-demo'
import './shared/demo/chart-bar-demo'
import './shared/demo/chart-pie-demo'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
